import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/understand-moebius-header-vector1.svg'
import Amoeba2 from 'assets/vectors/understand-moebius-header-vector2.svg'

export const Container = styled.div`
  width: 100%;
  height: 566px;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.tertiaryDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      flex-direction: column;
      height: 700px;
      justify-content: flex-start;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 543px;
      flex-direction: column;
      justify-content: flex-start;
    }
  `}
`

export const TitleContainer = styled.div`
  position: relative;
  max-width: 683px;
  margin-top: 100px;
  margin-right: 100px;

  span {
    ${({ theme }) => theme.fonts.heading4};
    color: ${({ theme }) => theme.colors.quaternaryMedium};
  }

  h1 {
    position: relative;
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-top: 40px;
      margin-right: 0px;
      padding-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 70px;
      margin-bottom: 30px;

      h1 {
        ${theme.fonts.heading3};
        margin-top: 20px;
      }
    }
  `}
`

export const DescriptionContainer = styled.div`
  position: relative;
  max-width: 444px;
  margin-top: 100px;

  p {
    position: relative;
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  span {
    ${({ theme }) => theme.fonts.largeText};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: 800px;
      margin-top: 40px;
      padding-left: 50px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      max-width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      max-width: 100%;
      padding: 0px 28px;
      margin-top: 0px;

      p {
        ${theme.fonts.smallTextSemiBold};
      }
      span {
        ${theme.fonts.mediumTextExtraBold};
      }
    }
  `}
`

export const LeftAmoeba = styled(Amoeba1)`
  position: absolute;
  top: 88px;
  left: -118px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 121px;
      width: 157px;
      top: -24px;
      left: -59px;
    }
  `}
`
export const RightAmoeba = styled(Amoeba2)`
  position: absolute;
  right: -165px;
  top: -75px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      display: none;
    }
  `}
`
