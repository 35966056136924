import styled from 'styled-components'
import Vector1 from 'assets/vectors/clinics-vector-1.svg'
import Vector2 from 'assets/vectors/clinics-vector-2.svg'
import Arrow from 'assets/vectors/arrow-head.svg'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};
`

export const Title = styled.p`
  ${({ theme }) => theme.fonts.heading3};
  color: ${({ theme }) => theme.colors.tertiaryDarkest};
  margin-top: 140px;
  margin-bottom: 79px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading4};
      text-align: center;
      max-width: 240px;
      margin-top: 70px;
      margin-bottom: 34px;
    }
  `}
`

export const Description = styled.p`
  max-width: 648px;
  ${({ theme }) => theme.fonts.largeBold};
  color: ${({ theme }) => theme.colors.neutralMedium};
  margin-top: 130px;
  text-align: center;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      max-width: 500px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumText};
      max-width: 300px;
      margin-top: 0px;
    }
  `}
`

export const HospitalsContainer = styled.div`
  display: flex;
  width: 1090px;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 80%;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 254px;
  height: 254px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.backgroundLight};
  overflow: hidden;

  ${({ theme }) => `
  @media (max-width: ${theme.breakPoints.tablet}) {
      margin-bottom: 36px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 167px;
      height: 167px;

      img {
        width: 50%;
        height: 50%;
      }
    }
  `}
`

export const CardsContainer = styled.div`
  position: relative;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 200px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 950px;
      justify-content: center;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 650px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      justify-content: center;
      margin-bottom: 74px;
    }
  `}
`

export const Card = styled.div`
  width: 579px;
  height: 389px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 33px;
  margin-bottom: 27px;
  border-radius: 8px;
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.backgroundLight};
  z-index: 2;

  ${({ theme }) => `
    @media (min-width: ${theme.breakPoints.smallTablet}) && (max-width: ${theme.breakPoints.tablet}) {
      width: 460px;
      height: 450px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 300px;
      height: 461px;
      padding: 44px 8px 30px 30px;
      margin-bottom: 53px;
    }
  `}
`

export const CardTop = styled.div``

export const CardBottom = styled.div`
  display: flex;
  align-items: center;
  a {
    ${({ theme }) => theme.fonts.buttomLink};
    color: ${({ theme }) => theme.colors.primaryDarkest};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      a {
        ${theme.fonts.smallTextSemiBold};
      }
    }
  `}
`

export const CardTitle = styled.p`
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.neutralMedium};
  text-transform: uppercase;
  margin-bottom: 8px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold};
    }
  `}
`

export const Subtitle = styled.p`
  ${({ theme }) => theme.fonts.mediumTextBlack};
  color: ${({ theme }) => theme.colors.primaryDarkest};
  margin-bottom: 16px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumTextBlack};
    }
  `}
`

export const Label = styled.p`
  ${({ theme }) => theme.fonts.smallTextSemiBold};
  color: ${({ theme }) => theme.colors.neutralMedium};
  margin-top: 17px;
  margin-bottom: 4px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      margin-top: 24px;
      margin-bottom: 8px;
    }
  `}
`

export const FieldValue = styled.p`
  ${({ theme }) => theme.fonts.mediumTextBold};
  color: ${({ theme }) => theme.colors.neutralMedium};
  white-space: pre-line;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextBlack};
      padding-right: 22px;
    }
  `}
`

export const AmoebaTop = styled(Vector2)`
  position: absolute;
  top: -154px;
  left: -154px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`
export const AmoebaBottom = styled(Vector1)`
  position: absolute;
  bottom: -54px;
  right: -82px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const ArrowIcon = styled(Arrow)`
  margin-left: 6px;
`
