import React from 'react'

import {
  Container,
  TitleContainer,
  DescriptionContainer,
  LeftAmoeba,
  RightAmoeba,
} from './style'

const Header = () => (
  <Container>
    <TitleContainer>
      <LeftAmoeba />
      <span>Entendendo Melhor</span>
      <h1>Instituições e Clínicas Especializadas</h1>
    </TitleContainer>
    <DescriptionContainer>
      <RightAmoeba />
      <p>
        Os Moebitas precisam de um tratamento multidisciplinar, que irá
        ajudá-los a ter mais qualidade de vida. Sabemos que nos grandes centros
        existem especialistas que estão dedicados à pesquisa e desenvolvimento
        de tratamentos para pessoas com a síndrome.
        <br />
        <br />
        <span>
          Vamos listar aqui hospitais e profissionais que podem lhe ajudar nessa
          jornada de superação.
        </span>
      </p>
    </DescriptionContainer>
  </Container>
)

export default Header
