import React from 'react'

import {
  Container,
  Title,
  Description,
  HospitalsContainer,
  LogoContainer,
  CardsContainer,
  Card,
  CardTop,
  CardBottom,
  CardTitle,
  Subtitle,
  Label,
  FieldValue,
  ArrowIcon,
  AmoebaBottom,
  AmoebaTop,
} from './style'

const Hospitals = ({ cardList, logos }) => (
  <Container>
    <Title>Principais grupos em hospitais:</Title>
    <HospitalsContainer>
      {logos.map((logo) => (
        <LogoContainer key={logo.id}>
          <img src={logo.fluid.src} alt={logo.title} />
        </LogoContainer>
      ))}
    </HospitalsContainer>
    <Description>
      Clínicas de tratamento de fonoaudiologia, fisioterapia, terapia
      ocupacional, etc.
    </Description>
    <CardsContainer>
      <AmoebaTop />
      <AmoebaBottom />
      {cardList.map((item) => (
        <Card key={item.sublocation}>
          <CardTop>
            {item.location && <CardTitle>{item.location}</CardTitle>}
            {item.sublocation && <Subtitle>{item.sublocation}</Subtitle>}
            {item.responsible && (
              <>
                <Label>Responsáveis:</Label>
                <FieldValue>{item.responsible}</FieldValue>
              </>
            )}
            {item.contact && (
              <>
                <Label>Contato:</Label>
                <FieldValue>{item.contact}</FieldValue>
              </>
            )}
            {item.address?.text && (
              <>
                <Label>Endereço:</Label>
                <FieldValue>{item.address?.text}</FieldValue>
              </>
            )}
            {item.phone?.number && (
              <>
                <Label>Telefone:</Label>
                <FieldValue>{item.phone?.number}</FieldValue>
              </>
            )}
          </CardTop>
          {item.url && (
            <CardBottom>
              <a href={item.url} target="_blank" rel="noreferrer">
                Visitar site
              </a>
              <ArrowIcon />
            </CardBottom>
          )}
        </Card>
      ))}
    </CardsContainer>
  </Container>
)

export default Hospitals
