import React from 'react'
import { ThemeProvider } from 'styled-components'

import useClinics from 'graphql/useClinics'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/Clinics/Header'
import Hospitals from 'components/Clinics/Hospitals'

const Clinics = () => {
  const data = useClinics()
  const logos = data.clinicsLogos.nodes[0].logo
  const clinics = data.clinics.nodes

  return (
    <ThemeProvider theme={theme}>
      <Layout css={{ backgroundColor: theme.colors.tertiaryDarkest }}>
        <Seo
          title="Instituições e Clínicas Especializadas"
          description="Os Moebitas precisam de um tratamento multidisciplinar, que irá ajudá-los a ter mais qualidade de vida. Sabemos que nos grandes centros existem especialistas que estão dedicados à pesquisa e desenvolvimento de tratamentos para pessoas com a síndrome. Vamos listar aqui hospitais e profissionais que podem lhe ajudar nessa jornada de superação."
        />
        <Header />
        <Hospitals cardList={clinics} logos={logos} />
      </Layout>
    </ThemeProvider>
  )
}

export default Clinics
