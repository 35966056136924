import { graphql, useStaticQuery } from 'gatsby'

export default () =>
  useStaticQuery(graphql`
    query {
      clinicsLogos: allContentfulLogoDosHospitaisPaginaInstituicoesEClinicas {
        nodes {
          logo {
            id
            title
            fluid {
              src
            }
          }
        }
      }
      clinics: allContentfulClinicasPaginaInstituicoesEClinicas {
        nodes {
          id
          location: departamento
          sublocation: local
          responsible: responsaveis
          contact: contato
          phone: telefone {
            number: telefone
          }
          address: endereco {
            text: endereco
          }
          url: linkDoSite
        }
      }
    }
  `)
